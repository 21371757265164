import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import HttpBackend from "i18next-http-backend";
// console.log(
//   `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json?lng={{lng}}&{{ns}}`
// );
import TRANSLATION_EN from "./locales/en/translation.json";
import TRANSLATION_CHI from "./locales/chi/translation.json";
// const languages = ["en", "chi"];
i18n
  .use(LanguageDetector)
  // .use(HttpBackend)
  .use(initReactI18next)

  .init({
    debug: true,
    // defaultNS: "menus",
    fallbackLng: "en",
    // whitelist: languages,
    // lng: "en",

    resources: {
      en: {
        translation: TRANSLATION_EN,
        // translation: `${process.env.PUBLIC_URL}/locales/en/translation.json`,
      },
      chi: {
        translation: TRANSLATION_CHI,
        // translation: `${process.env.PUBLIC_URL}/locales/chi/translation.json`,
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
