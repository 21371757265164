import React from "react";
import { Row, Col } from "react-bootstrap";
import { Header, Footer } from "../components/index";
import { ChainImg, Fee } from "../components/images";
import { useTranslation } from "react-i18next";
export function HelpBox() {
  const { t } = useTranslation();
  return (
    <div className="box history-box">
      <div className="content-main">
        <div className="title">
          <em>
            <span>{t("help.tip")}</span> - {t("help.tip_para")}
            <br />
            {t("help.blockchain")}: {t("help.blockchain_para")}
          </em>
        </div>
        <div className="title">
          <em>
            <span>{t("help.warning")}</span> - {t("help.warning_para1")}
            <br />
            {t("help.warning_para2")}
          </em>
        </div>
        <p className="span-blue">{t("help.for_all_usdtd")} </p>
        <ul>
          <li>
            <p>{t("help.deposit_amount")}</p>
          </li>
        </ul>
        <div className="cross-line"></div>
        <div className="title">
          <em>{t("help.following_fee")}</em>
        </div>
        <ul>
          <li>
            <p className="mb-4">
              <span className="span-blue">{t("help.Withdrawal_fee")}: </span>
              {t("help.Withdrawal_para")}
            </p>
          </li>
          <li>
            <p>
              <span className="span-blue">{t("help.service_fee")}: </span>
              {t("help.service_fee_para")}
            </p>
          </li>
        </ul>
        <div className="cross-line"></div>
        <img src={Fee} alt="Fee-table.png" />
        <p>({t("help.table_para")})</p>
        <div className="cross-line"></div>
        <div className="title">
          <em>{t("help.Contact")}</em>
        </div>
        <p>
          {t("help.query")}{" "}
          <a href="mailto:cross@usdt-defi.io">cross@usdt-defi.io</a>
        </p>
      </div>
    </div>
  );
}

function Help() {
  return (
    <div>
      <div className="bg-img-main">
        <div className="bg-img"></div>
      </div>
      <Header />
      {/* ========================Home-Banner===================  */}
      <section id="banner">
        <div className="custom-container">
          <div className="sec-padding">
            <Row>
              <Col lg={12}>
                <div className="main">
                  <HelpBox />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Help;
